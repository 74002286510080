import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Page from "../components/page/page.js";
import Banner from "../components/banner/banner.js";
import appstore from "../images/appstore.png";
import playstore from "../images/playstore.png";

const PureHome = ({ data }) => {
  const { title, description } = data.site.siteMetadata;

  return (
    <Page>
      <Banner title={title} subtitle={description}>
        <ul className="stores">
          <li>
            <a
              href="https://apps.apple.com/us/app/sg-land-transport/id1504247137?ls=1"
              title="Download on AppStore"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appstore} alt="App Store" />
            </a>
          </li>
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.saschaderungs.ltaDatamall"
              title="Download on AppStore"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={playstore} alt="Play Store" />
            </a>
          </li>
        </ul>
      </Banner>
    </Page>
  );
};

export const Home = () => {
  const data = useStaticQuery(graphql`
    query MySiteMetaData {
      site {
        ...SiteDataFragment
      }
    }
  `);

  return <PureHome data={data} />;
};

export default Home;
