import React from "react";
import handphone from "./hand-phone.png";
import bannerStyles from "./banner.module.scss";

const Banner = (props) => {
  return (
    <div className={bannerStyles.banner}>
      <div className={bannerStyles.banner__content}>
        <img
          className={bannerStyles.banner__image}
          src={handphone}
          alt="App Icon"
        />
        <div className={bannerStyles.banner__wording}>
          <h1 className={bannerStyles.title}>{props.title}</h1>
          <h2 className={bannerStyles.subtitle}>{props.subtitle}</h2>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Banner;
